<template>
  <div>
    <!-- Título principal -->
    <div class="d-flex align-center justify-space-between mb-10 my-8">
      <v-btn icon rounded @click="$router.go(-1)">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon dense v-bind="attrs" v-on="on">
              <v-icon size="28">mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>Volver</span>
        </v-tooltip>
      </v-btn>
      <h1 class="justify-center">Especialidades</h1>
      <v-btn color="primary rounded-lg text-capitalize" @click="addEspecialidad = true">Agregar
      </v-btn>
    </div>
    <!-- content especialidad -->
    <div v-if="licen.Var22">
      <!-- Información desplegada al pinchar "ingresar" -->
      <div v-if="addEspecialidad" class="d-flex">
        <v-text-field dense outlined v-model="especialidad.Nespecialidad" class="mx-1" label="Nombre"></v-text-field>
        <v-btn :loading="activeLoadingCreateEspecialidad" :disabled="!especialidad.Nespecialidad || activeLoadingCreateEspecialidad
        " @click="btnSaveEspecialidad" icon color="primary"
          class="mx-1 rounded-lg"><v-icon>mdi-checkbox-marked-circle-plus-outline</v-icon></v-btn>
        <v-btn @click="addEspecialidad = false" icon class="chip_button rounded-lg mx-1"
          color="primary"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <div v-if="activeUpdateEspecialidad" class="d-flex pa-2">
        <v-text-field outlined dense label="Especialidad" v-model="updateNombreEspecialidad"
          class="mr-2"></v-text-field>
        <v-btn icon color="primary" :loading="activeUploadingUpdateEspecialidad" class="text-capitalize mr-2 rounded-lg"
          @click="btnUpdateEspecialidad"><v-icon>mdi-checkbox-marked-circle-plus-outline</v-icon></v-btn>
        <v-btn @click="activeUpdateEspecialidad = false" icon class="text-capitalize chip_button rounded-lg"
          color="primary"><v-icon>mdi-close</v-icon></v-btn>
      </div>

      <!-- Tarjeta del contenido principal -->
      <v-card width="100%" class="pa-3 mb-5 rounded-xl mt-5">
        <div class="pa-3">
          <v-text-field v-model="search" append-icon="mdi-magnify" single-line hide-details class="mb-1"></v-text-field>
          <v-data-table :headers="headers" :items="especialidades" :search="search" :footer-props="{
        'items-per-page-text': 'Filas por página',
      }">
            <template v-slot:item.actions="{ item }">
              <div class="actions">
                <v-btn icon @click="btnSelectUpdateNombreEspecie(item)">
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn icon :loading="activeUploadingDeleteEspecialidad" @click="btnSelectDeleteEspecialidad(item)">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </div>
            </template>
            <template v-slot:no-data>
              <v-alert :value="true"> No hay resultados disponibles </v-alert>
            </template>
          </v-data-table>
        </div>
      </v-card>
      <v-snackbar color="primary" v-model="successCreateEspecialidad" multiLine>
        Especialidad creada con éxito
      </v-snackbar>
      <v-snackbar color="primary" v-model="successUpdateEspecialidad" multiLine>
        Especialidad actualizada con éxito
      </v-snackbar>
      <v-snackbar color="primary" v-model="successDeleteEspecialidad" multiLine>
        Especialidad eliminada con éxito
      </v-snackbar>

    </div>
    <div v-else class="d-flex justify-center pa-3 ma-3">
      <div class="d-flex flex-column">
        <v-avatar tile size="300" class="d-flex justify-center">
          <v-img contain sizes="200" src="/icons/notselect.svg"></v-img>
        </v-avatar>
        <div class="d-flex justify-center">
          <h3 class="mt-3 ml-3">
            No tiene los permisos necesarios para <br />
            acceder a los datos
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      addEspecialidad: false,
      search: "",
      headers: [{ text: "Especialidad", value: "Nespecialidad" }, { text: "Acciones", value: "actions" }],
      especialidad: {
        Codigo: 0,
        Nespecialidad: "",
      },
      activeLoadingCreateEspecialidad: false,
      activeUploadingDeleteEspecialidad: false,
      activeUploadingUpdateEspecialidad: false,
      activeUpdateEspecialidad: false,
      updateNombreEspecialidad: "",
      updateEspecialidadId: 0,
    };
  },
  watch: {
    successCreateEspecialidad: function (val) {
      if (val) {
        this.especialidad.Nespecialidad = "";
        this.addEspecialidad = false;
        this.activeLoadingCreateEspecialidad = false;
      }
    },
    successDeleteEspecialidad: function (val) {
      if (val) {
        this.activeUploadingDeleteEspecialidad = false;
      }
    },
    successUpdateEspecialidad: function (val) {
      if (val) {
        this.activeUploadingUpdateEspecialidad = false;
        this.activeUpdateEspecialidad = false;
      }
    },
  },
  computed: {
    ...mapState("medical-module", [
      "especialidades",
      "successCreateEspecialidad",
      "successUpdateEspecialidad",
      "successDeleteEspecialidad"
    ]),
    ...mapState("auth-module", ["licen"]),
  },
  methods: {
    ...mapActions("medical-module", ["postEspecialidad", "updateEspecialidad", "deleteEspecialidad"]),
    btnSaveEspecialidad() {
      this.activeLoadingCreateEspecialidad = true;
      this.postEspecialidad({ especialidad: this.especialidad });
    },
    async btnUpdateEspecialidad() {
      this.activeUploadingUpdateEspecialidad = true;
      await this.updateEspecialidad({
        especialidad: {
          id: this.updateEspecialidadId,
          Codigo: this.updateEspecialidadId,
          Nespecialidad: this.updateNombreEspecialidad,
        },
      });
    },
    btnSelectUpdateNombreEspecie(especialidad) {
      this.activeUpdateEspecialidad = true;
      this.updateNombreEspecialidad = especialidad.Nespecialidad;
      this.updateEspecialidadId = especialidad.Id;
    },
    async btnSelectDeleteEspecialidad(especialidad) {
      if (window.confirm("¿Está seguro de eliminar la especialidad " + especialidad.Nespecialidad + "?")) {
        this.activeUploadingDeleteEspecialidad = true;
        await this.deleteEspecialidad({ id: especialidad.Id });
      }
    },

  },

};
</script>
<style>
/* Eliminar select Sort By desplegado en vista móvil de tabla  */
.v-data-table-header-mobile__wrapper {
  display: none;
  border-style: none;
}

.v-data-table-header-mobile {
  display: none;
}
</style>
